import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, StaticQuery } from "gatsby"

/**
 * SEO component that queries for data with
 *  Gatsby's StaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Seo = ({ description, lang, meta, title }) => {
  return (
    <>
      <StaticQuery 
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
              }
            }
          }
        `}
        render={ data => (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={data.site.siteMetadata.title}
            titleTemplate={`%s - ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: data.site.siteMetadata.description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: data.site.siteMetadata.description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: data.site.siteMetadata.title,
              },
              {
                name: `twitter:description`,
                content: data.site.siteMetadata.description,
              },
            ].concat(meta)}
          />
        )}
      />
    </>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo;
